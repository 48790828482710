import {EditorState} from "prosemirror-state"
import {EditorView} from "prosemirror-view"
import {schema, defaultMarkdownParser, defaultMarkdownSerializer} from "prosemirror-markdown"
import {exampleSetup} from "prosemirror-example-setup"

import "prosemirror-gapcursor/style/gapcursor.css"
import "prosemirror-menu/style/menu.css"
import "prosemirror-view/style/prosemirror.css"
import "prosemirror-example-setup/style/style.css"

document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('.markdown-content').forEach(mdc => {
    let textArea = mdc.querySelector(".content"),
        editor = mdc.querySelector(".markdown-editor");

    let view = new EditorView(editor, {
      state: EditorState.create({
        doc: defaultMarkdownParser.parse(textArea.value),
        plugins: exampleSetup({schema})
      })
    })

    // Guardar los cambios al enviar el formulario y cada 10 segundos
    textArea.form.addEventListener('submit', e => textArea.value = defaultMarkdownSerializer.serialize(view.state.doc));
    setInterval(() => textArea.value = defaultMarkdownSerializer.serialize(view.state.doc), 10 * 1000);
    // Ocultar el area
    textArea.style.display = 'none'
  })
})
