import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["invalid", "submitting"];

  // @todo Stimulus >1
  get submittingIdValue() {
    return this.element.dataset?.formValidationSubmittingIdValue;
  }

  // @todo Stimulus >1
  get invalidIdValue() {
    return this.element.dataset?.formValidationInvalidIdValue;
  }

  connect() {
    this.element.setAttribute("novalidate", true);

    for (const input of this.element.elements) {
      if (input.type === "button" || input.type === "submit") continue;

      if (input.dataset.action) {
        input.dataset.action = `${input.dataset.action} htmx:validation:validate->form-validation#submit`;
      } else {
        input.dataset.action = "htmx:validation:validate->form-validation#submit";
      }
    }
  }

  submit(event = undefined) {
    if (this.submitting) return;

    this.submitting = true;

    event?.preventDefault();

    if (this.element.reportValidity()) {
      this.element.classList.remove("was-validated");

      if (!this.element.getAttributeNames().some(x => x.startsWith("hx-"))) this.element.submit();

      window.dispatchEvent(new CustomEvent("notification:show", { detail: { value: this.submittingIdValue } }));
    } else {
      event?.stopPropagation();

      this.element.classList.add("was-validated");

      window.dispatchEvent(new CustomEvent("notification:show", { detail: { value: this.invalidIdValue } }));
    }

    this.submitting = false;
  }
}
