// Lista de equivalencias entre Date#getTimezoneOffset de JS y
// MetadataEvent
const timeZoneOffsets = {
   '720': '-12:00',
   '660': '-11:00',
   '600': '-10:00',
   '570': '-09:30',
   '540': '-09:00',
   '480': '-08:00',
   '420': '-07:00',
   '360': '-06:00',
   '300': '-05:00',
   '240': '-04:00',
   '210': '-03:30',
   '180': '-03:00',
   '120': '-02:00',
    '60': '-01:00',
     '0': '00:00',
   '-60': '+01:00',
  '-120': '+02:00',
  '-180': '+03:00',
  '-210': '+03:30',
  '-240': '+04:00',
  '-270': '+04:30',
  '-300': '+05:00',
  '-330': '+05:30',
  '-345': '+05:45',
  '-360': '+06:00',
  '-390': '+06:30',
  '-420': '+07:00',
  '-480': '+08:00',
  '-525': '+08:45',
  '-540': '+09:00',
  '-570': '+09:30',
  '-600': '+10:00',
  '-630': '+10:30',
  '-660': '+11:00',
  '-720': '+12:00',
  '-765': '+12:45',
  '-780': '+13:00',
  '-840': '+14:00'
};

// Obtiene el huso horario local 
const timeZoneOffset = timeZoneOffsets[(new Date).getTimezoneOffset().toString()];

document.addEventListener('turbolinks:load', () => {
  // Aplicar el huso horario descubierto en los campos de evento solo
  // cuando estamos creando un artículo.
  document.querySelectorAll('.new .event .zone select').forEach(zone => zone.value = timeZoneOffset);
})
