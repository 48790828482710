import { Editor } from "editor/editor";
import { EditorNode } from "editor/types";
import { markNames, setAuxiliaryToolbar, clearSelected } from "editor/utils";

function select(editor: Editor, el: HTMLAnchorElement): void {
  clearSelected(editor);
  el.dataset.editorSelected = "";
  editor.toolbar.auxiliary.link.urlEl.value = el.href;
  setAuxiliaryToolbar(editor, editor.toolbar.auxiliary.link.parentEl);
}

export const link: EditorNode = {
  selector: "a",
  allowedChildren: [...markNames.filter((n) => n !== "link"), "text"],
  handleEmpty: "remove",
  create: () => document.createElement("a"),
  onClick(editor, el) {
    if (!(el instanceof HTMLAnchorElement)) throw new Error("oh no");
    select(editor, el);
  },
};

export function setupAuxiliaryToolbar(editor: Editor): void {
  editor.toolbar.auxiliary.link.urlEl.addEventListener("input", (event) => {
    const url = editor.toolbar.auxiliary.link.urlEl.value;
    const selectedEl = editor.contentEl.querySelector<HTMLAnchorElement>(
      "a[data-editor-selected]"
    );
    if (!selectedEl)
      throw new Error("No pude encontrar el link para setear el enlace");

    selectedEl.href = url;
  });
  editor.toolbar.auxiliary.link.urlEl.addEventListener("keydown", (event) => {
    if (event.keyCode == 13) event.preventDefault();
  });
}
