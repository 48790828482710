// Cancela las peticiones pendientes de htmx para todos los elementos al
// cambiar de página.
document.addEventListener("turbolinks:click", () => {
  for (const hx of document.querySelectorAll("[hx-get]")) {
    window.htmx.trigger(hx, "htmx:abort");
  }
});

document.addEventListener("htmx:resetForm", (event) => {
  event.target.reset();
});
