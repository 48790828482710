import { Controller } from "@hotwired/stimulus";

/*
 * Para poder indicar que al menos uno del grupo de checkboxes es
 * obligatorio, marcamos uno como `required` (que es el que mostraría el
 * error) y se lo quitamos cuando detectamos que alguno cambió.
 */
export default class extends Controller {
  static targets = ["required", "checkbox"];

  connect() {
  }

  checkboxTargetConnected(checkboxTarget) {
    if (checkboxTarget.checked) {
      this.requiredTarget.required = false;
      this.revalid();
    }
  }

  /*
   * El grupo deja de ser obligatorio cuando al menos uno está activo.
   */
  change(event = undefined) {
    if (event.target.checked) {
      this.requiredTarget.required = false;
    } else {
      this.requiredTarget.required = !Array.from(this.checkboxTargets).some(x => x.checked);
    }

    for (const checkbox of this.checkboxTargets) {
      if (checkbox === event.target) continue;

      checkbox.required = !event.target.checked;
    }
  }

  /*
   * Si el checkbox es considerado inválido, transmitir todos los
   * estados a los checkboxes.
   */
  invalid(event = undefined) {
    for (const checkbox of this.checkboxTargets) {
      checkbox.required = true;
    }
  }

  revalid(event = undefined) {
    for (const checkbox of this.checkboxTargets) {
      checkbox.required = false;
    }
  }
}
