import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggle", "input"];

  toggle(event = undefined) {
    this.inputTargets.forEach(input => {
      input.checked = this.toggleTarget.checked;
    });
  }
}
