import { Controller } from "@hotwired/stimulus";
import SuttyEditor from "@suttyweb/editor";

import "@suttyweb/editor/dist/style.css";

export default class extends Controller {
  static targets = ["textarea"];

  connect() {
    this.editor =
      new SuttyEditor({
        target: this.element,
        props: {
          textareaEl: this.textareaTarget,
        },
      });
  }
}
