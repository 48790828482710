import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  /*
   * Previene el envío de un formulario al presionar enter
   */
  prevent(event) {
    if (event.key == "Enter") event.preventDefault();
  }
}
