import { Editor } from "editor/editor";

/*
 * Guarda una copia local de los cambios para poder recuperarlos
 * después.
 *
 * Usamos la URL completa sin anchors.
 */
function getStorageKey(editor: Editor): string {
  const keyEl = editor.editorEl.querySelector<HTMLInputElement>(
    '[data-target="storage-key"]'
  );
  if (!keyEl)
    throw new Error("No encuentro la llave para guardar los artículos");
  return keyEl.value;
}

export function forgetContent(storedKey: string): void {
  window.localStorage.removeItem(storedKey);
}

export function storeContent(editor: Editor): void {
  if (editor.contentEl.innerText.trim().length === 0) return;

  window.localStorage.setItem(
    getStorageKey(editor),
    editor.contentEl.innerHTML
  );
}

export function restoreContent(editor: Editor): void {
  const content = window.localStorage.getItem(getStorageKey(editor));

  if (!content) return;
  if (content.trim().length === 0) return;

  editor.contentEl.innerHTML = content;
}
