import { Editor } from "editor/editor";
import { EditorNode } from "editor/types";
import { markNames, setAuxiliaryToolbar, clearSelected } from "editor/utils";

const hex = (x: string) => ("0" + parseInt(x).toString(16)).slice(-2);
// https://stackoverflow.com/a/3627747
// TODO: cambiar por una solución más copada
function rgbToHex(rgb: string): string {
  const matches = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
  if (!matches) throw new Error("no pude parsear el rgb()");
  return "#" + hex(matches[1]) + hex(matches[2]) + hex(matches[3]);
}

function select(editor: Editor, el: HTMLElement): void {
  clearSelected(editor);
  el.dataset.editorSelected = "";
  editor.toolbar.auxiliary.mark.colorEl.value = el.style.backgroundColor
    ? rgbToHex(el.style.backgroundColor)
    : "#f206f9";
  editor.toolbar.auxiliary.mark.textColorEl.value = el.style.color
    ? rgbToHex(el.style.color)
    : "#000000";
  setAuxiliaryToolbar(editor, editor.toolbar.auxiliary.mark.parentEl);
}

export const mark: EditorNode = {
  selector: "mark",
  allowedChildren: [...markNames.filter((n) => n !== "mark"), "text"],
  handleEmpty: "remove",
  create: () => document.createElement("mark"),
  onClick(editor, el) {
    if (!(el instanceof HTMLElement)) throw new Error("oh no");
    select(editor, el);
  },
};

export function setupAuxiliaryToolbar(editor: Editor): void {
  editor.toolbar.auxiliary.mark.colorEl.addEventListener("input", (event) => {
    const color = editor.toolbar.auxiliary.mark.colorEl.value;
    const selectedEl = editor.contentEl.querySelector<HTMLElement>(
      "mark[data-editor-selected]"
    );
    if (!selectedEl)
      throw new Error("No pude encontrar el mark para setear el color");

    selectedEl.style.backgroundColor = color;
  });
  editor.toolbar.auxiliary.mark.textColorEl.addEventListener(
    "input",
    (event) => {
      const color = editor.toolbar.auxiliary.mark.textColorEl.value;
      const selectedEl = editor.contentEl.querySelector<HTMLElement>(
        "mark[data-editor-selected]"
      );
      if (!selectedEl)
        throw new Error(
          "No pude encontrar el mark para setear el color del text"
        );

      selectedEl.style.color = color;
    }
  );
  editor.toolbar.auxiliary.mark.colorEl.addEventListener("keydown", (event) => {
    if (event.keyCode == 13) event.preventDefault();
  });
}
