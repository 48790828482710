/* eslint no-console:0 */

import { Notifier } from '@airbrake/browser'

try {
  window.airbrake = new Notifier({
    projectId: window.env.AIRBRAKE_PROJECT_ID,
    projectKey: window.env.AIRBRAKE_PROJECT_KEY,
    host: window.env.PANEL_URL
  });

  const ignoredErrors = ["htmx:afterRequest", "htmx:sendAbort"];

  console.originalError = console.error;
  console.error = (...e) => {
    const msg = e.join(" ");

    if (!ignoredErrors.some(x => msg.includes(x))) {
      window.airbrake.notify(e.join(" "));
    }

    return console.originalError(...e);
  };
} catch(e) {
  console.error(e);
}

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'controllers'
import 'editor/editor'
import 'fork-awesome/scss/fork-awesome.scss'
import 'etc'

import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import 'chartkick/chart.js'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.htmx = require("@suttyweb/htmx.org/dist/htmx.cjs.js");
window.htmx.config.selfRequestsOnly = true;
