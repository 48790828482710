import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    const state = window.sessionStorage.getItem(this.element.id);

    if (state === "open") {
      this.element.setAttribute("open", true);
    }
  }

  store(event = undefined) {
    window.sessionStorage.setItem(this.element.id, event.newState);
  }
}
