import { Controller } from '@hotwired/stimulus'
import bsCustomFileInput from "bs-custom-file-input";

document.addEventListener("turbolinks:load", () => {
  bsCustomFileInput.init();
});

export default class extends Controller {
  static targets = ["preview", "input"];

  connect() {
  }

  update(event = undefined) {
    if (!this.hasPreviewTarget) return;

    this.previewTarget.src = window.URL.createObjectURL(this.inputTarget.files[0])
  }
}
