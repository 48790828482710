import { Controller } from "@hotwired/stimulus";

/*
 * Solo se puede mostrar una notificación a la vez
 */
export default class extends Controller {
  // @todo Stimulus >1
  get showClasses() {
    return (this.element.dataset?.notificationShowClass || "").split(" ").filter(x => x);
  }

  // @todo Stimulus >1
  get hideClasses() {
    return (this.element.dataset?.notificationHideClass || "").split(" ").filter(x => x);
  }

  /*
   * Al recibir el evento de mostrar, si no está dirigido al elemento
   * actual, se oculta.
   */
  show(event = undefined) {
    if (event?.detail?.value !== this.element.id) {
      this.hide({ detail: { value: this.element.id } });
      return;
    }

    this.element.classList.remove("d-none");

    setTimeout(() => {
      this.element.classList.remove(...this.hideClasses);
      this.element.classList.add(...this.showClasses);
    }, 1);
  }

  hide(event = undefined) {
    if (event?.detail?.value !== this.element.id) return;

    this.element.classList.remove(...this.showClasses);
    this.element.classList.add(...this.hideClasses);

    setTimeout(() => this.element.classList.add("d-none"), 150);
  }
}
