import { Editor } from "editor/editor";
import {
  safeGetSelection,
  safeGetRangeAt,
  moveChildren,
  blockNames,
  parentBlockNames,
} from "editor/utils";
import { EditorNode, getType, getValidParentInSelection } from "editor/types";

function makeParentBlock(
  tag: string,
  create: EditorNode["create"]
): EditorNode {
  return {
    selector: tag,
    allowedChildren: [...blockNames, "multimedia"],
    handleEmpty: "remove",
    create,
  };
}

// XXX: si agregás algo acá, probablemente le quieras hacer un botón
// en app/views/posts/attributes/_content.haml
export const parentBlocks: { [propName: string]: EditorNode } = {
  left: makeParentBlock("div[data-align=left]", () => {
    const el = document.createElement("div");
    el.dataset.align = "left";
    el.style.textAlign = "left";
    return el;
  }),
  center: makeParentBlock("div[data-align=center]", () => {
    const el = document.createElement("div");
    el.dataset.align = "center";
    el.style.textAlign = "center";
    return el;
  }),
  right: makeParentBlock("div[data-align=right]", () => {
    const el = document.createElement("div");
    el.dataset.align = "right";
    el.style.textAlign = "right";
    return el;
  }),
};

export function setupButtons(editor: Editor): void {
  for (const [name, type] of Object.entries(parentBlocks)) {
    const buttonEl = editor.toolbarEl.querySelector(
      `[data-editor-button="parentBlock-${name}"]`
    );
    if (!buttonEl) continue;
    buttonEl.addEventListener("click", (event) => {
      event.preventDefault();

      // TODO: Esto solo mueve el bloque en el que está el final de la selección
      // (anchorNode). quizás lo podemos hacer al revés (iterar desde contentEl
      // para encontrar los bloques que están seleccionados y moverlos/cambiarles
      // el parentBlock)

      const list = getValidParentInSelection({ editor, type: name });

      const replacementEl = type.create(editor);
      if (list[0] == editor.contentEl) {
        // no está en un parentBlock
        editor.contentEl.insertBefore(replacementEl, list[1]);
        replacementEl.appendChild(list[1]);
      } else {
        // está en un parentBlock
        moveChildren(list[0], replacementEl, null);
        editor.contentEl.replaceChild(replacementEl, list[0]);
      }
      window.getSelection()?.collapse(replacementEl);

      return false;
    });
  }
}
