import InputTag from 'input-tag/input-tag'
import InputMap from 'input-map/input-map'

document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('.taggable').forEach(target => {
    target.innerHTML = ''

    new InputTag({ target, props: { ...target.dataset } })
  })

  document.querySelectorAll('.mapable').forEach(target => {
    target.innerHTML = ''

    new InputMap({ target, props: { ...target.dataset } })
  })
})
