// Validar fechas en navegadores que no soportan date, como
// Webkit/Safari
document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('input[type="date"]').forEach(date => {
    if (date.type === 'date') return

    date.addEventListener('change', event => {
      date.setCustomValidity(date.validity.patternMismatch ? date.dataset.patternMismatch : '')
    })
  })
})
